<template>
	<span>
		<font-awesome-icon
			icon="fa-solid fa-angle-down"
			size="1x"
			class="mobile-dropdown"
			:class="{ active }"
			@click="active = !active"
		/>
		<slide-up-down :model-value="hideAll ? resetState() : active" :duration="300">
			<ul class="submenu">
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="$emit('hide-menu')">{{ item.header }}</nuxt-link>
				</li>
			</ul>
		</slide-up-down>
	</span>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

defineEmits(['hide-menu']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};
</script>

<style lang="scss" scoped>
a {
	font-weight: 400;
	color: #fff;

	&.active,
	&:hover {
		color: var(--cta-color);
	}
}

.mobile-dropdown {
	vertical-align: middle;
	display: inline-block;
	cursor: pointer;
	margin-left: 10px;
	margin-top: -15px;

	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	list-style: none;
	padding: 10px 0 0;

	&.active {
		display: block;
	}

	li,
	li a {
		white-space: nowrap;
		font-size: 24px;
		line-height: 32px;
		text-transform: uppercase;

		&:hover,
		&.active {
			color: #e1d5a6;
		}
	}
}

@media (max-width: 800px) {
	.mobile-dropdown {
		margin-top: -8px;
	}

	.submenu li a {
		font-size: 18px;
	}
}
</style>
